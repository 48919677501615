<template lang="pug">
client-only(v-if="availableLocales")
	SingaDropdown(aria-role="list" :mobile-modal="false" position="bottom-left")
		template(#trigger="{ active }")
			SingaButton.dropdown-button-regular.is-transparent-dark.is-rounded(
				:label="localeProperties.name"
				:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
		ul
			SingaDropdownItem.item(v-for="i in availableLocales" :key="i.code" aria-role="listitem" @click="setLocale(i.code)" tag="li") {{i.name}}
</template>

<script setup lang="ts">
const { locales, localeProperties, setLocale } = useI18n()
// const switchLocalePath = useSwitchLocalePath()

defineProps({
	fullWidth: {
		type: Boolean,
		required: true
	}
})
const availableLocales = computed(() => {
	// @ts-ignore
	return (locales.value).filter(i => i.code !== localeProperties.value.code) as LocaleObject[]
})
</script>

<style lang="sass" scoped>
.item
	background-color: $color-grey-80
	@include font(basier, regular)
	&:hover
		background-color: $transparent-white-8
		color: white
</style>
